export const BG_PRIMARY = '#5E0517';
export const BG_PRIMARY_GRADIENT = '#36020d';
export const BG_SECONDARY = '#87212E';
export const BG_ALTERNATIVE = '#87212E';
export const TEXT_PRIMARY = '#D9B79A';
export const TEXT_SECONDARY = BG_PRIMARY;

// Icon & Navigation
export const BG_NAVIGATION = `#D9B79A`;
export const BG_NAVIGATION_ICON_COLOR = `#000`;

// Frame
export const WEDDING_BORDER_COLOR = `transparent`;

// DEFAULT STYLES
export const BUTTON_COLOR_TEXT = '#D9B79A';
export const DEFAULT_BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
export const DEFAULT_OPTIONS_PROPS = {
  style: { color: 'black' },
};
